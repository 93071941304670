import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, InputGroup, CustomInput } from 'reactstrap';
import CountUp from 'react-countup';
import CardSummary from './CardSummary';
import ActiveUsersBarChart from './ActiveUsersBarChart';
import PaymentsLineChart from './PaymentsLineChart';
import { toast } from 'react-toastify';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';

import loadable from '@loadable/component';
import DashBoardDepositStatus from './DashboardDepositStatus';
import { service } from '../../network/service';

const PurchasesTable = loadable(() => import('./PurchasesTable'));
const ActiveUsersMap = loadable(() => import('./ActiveUsersMap'));

const Dashboard = () => {
  // State
  const [isSelected, setIsSelected] = useState(false);
  const [clientCount, setClientCount] = useState(0);
  const [organizationCount, setOrganizationCount] = useState(0);
  const [locationCount, setLocationCount] = useState(0);
  const [deviceCount, setDeviceCount] = useState(0);

  useEffect(() => {
    
    toast(
      <Fragment>
        Welcome to <strong>Gizmott</strong>!<br />
      </Fragment>
    );
    // service.allCount()
    //     .then((response) => {
    //       console.log('in the response=', response);
    //       let clientCount = 0;
    //       let organizationCount = 0;
    //       let locationCount = 0;
    //       let deviceCount = 0;
    //       if (response.client.length > 0) {
    //         clientCount = response.client[0].client_count;
    //         setClientCount(clientCount);
    //       }
    //       if (response.organization.length > 0) {
    //         organizationCount = response.organization[0].organization_count;
    //         setOrganizationCount(organizationCount);
    //       }
    //       if (response.location.length > 0) {
    //         locationCount = response.location[0].location_count;
    //         setLocationCount(locationCount);
    //       }
    //       if (response.device.length > 0) {
    //         deviceCount = response.device[0].device_count;
    //         setDeviceCount(deviceCount);
    //       }
    //     },
    //     (error) => {
    //       //setRedirect(false);
    //       console.log(error);
    //       console.log('in the error block');return false;
    //     });
  }, []);
  //componentDidMount() {
   // e.preventDefault();
    
// }

  return (
    <Fragment>
      <PaymentsLineChart />
      <DashBoardDepositStatus />
      <div className="card-deck">
        <CardSummary  title="Clients" color="warning" >
         {clientCount}
        </CardSummary>
        <CardSummary title="User Names" color="info" >
         {organizationCount}
        </CardSummary>
        {/* <CardSummary content="43,594" rate="9.54%" title="Revenue" color="success" linkText="Statistics">
          <CountUp end={43594} duration={5} prefix="$" separator="," decimal="." />
        </CardSummary> */}
        <CardSummary title="Locations" color="success" >
         {locationCount}
        </CardSummary>
        <CardSummary title="Devices" color="info" >
         {deviceCount}
        </CardSummary>
      </div>
      <Card className="mb-3">
        <FalconCardHeader title="Recent Purchases" light={false}>
          {isSelected ? (
            <InputGroup size="sm" className="input-group input-group-sm">
              <CustomInput type="select" id="bulk-select">
                <option>Bulk actions</option>
                <option value="Refund">Refund</option>
                <option value="Delete">Delete</option>
                <option value="Archive">Archive</option>
              </CustomInput>
              <Button color="falcon-default" size="sm" className="ml-2">
                Apply
              </Button>
            </InputGroup>
          ) : (
            <Fragment>
              <ButtonIcon icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
                New
              </ButtonIcon>
              <ButtonIcon icon="filter" transform="shrink-3 down-2" color="falcon-default" size="sm" className="mx-2">
                Filter
              </ButtonIcon>
              <ButtonIcon icon="external-link-alt" transform="shrink-3 down-2" color="falcon-default" size="sm">
                Export
              </ButtonIcon>
            </Fragment>
          )}
        </FalconCardHeader>
        <CardBody className="p-0">
          <PurchasesTable setIsSelected={setIsSelected} />
        </CardBody>
      </Card>
      <Row noGutters>
        <Col lg="4" className="pr-lg-2">
          <ActiveUsersBarChart />
        </Col>
        <Col lg="8" className="pl-lg-2">
          <ActiveUsersMap />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Dashboard;
