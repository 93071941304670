import axios from "axios";

function addDay(date, day) {
  // used to add day to the current date
  var date = new Date(date);
  date.setDate(date.getDate() + +day);

  return date.toISOString().slice(0, 10);
}

const getCount = (pub_id, roll_id, channel_id) => {
  console.log(`${process.env.REACT_APP_API_SERVER_HOST}/home/count`);
  let url = `${process.env.REACT_APP_API_SERVER_HOST}/home/count`;
  let header = {
    headers: {
      pubid: pub_id,
      role_id: roll_id,
     content_partner_id:parseInt(localStorage.getItem("contentPartnerId"))
    }
  };
  if(channel_id){
    header.headers.channelid= channel_id && channel_id
  }
  return axios
    .get(url, header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};

const getRequestCount = (pub_id, roll_id) => {
  console.log(
    `${
      process.env.REACT_APP_API_SERVER_HOST
    }/home/requests?pub_id=${pub_id}&roll_id=${roll_id}`
  );
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/requests?pub_id=${pub_id}&roll_id=${roll_id}`;

  return axios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};

const getEcommerceCount = () => {
  let header = {
      headers: {
        pubid: localStorage.getItem("pub_id"),
      }
    };
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/count?`;

  return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};
const getEcommerceOrderGraph = () => {
  let header = {
      headers: {
        pubid: localStorage.getItem("pub_id"),
        channelid:localStorage.getItem('currentChannel')
      }
    };
    var date = new Date();
    var end_date = date.toISOString().slice(0,10);
    let start_date = addDay(date,-7)


  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/graph/orders?start_date=${start_date}&end_date=${end_date}`;

  return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};

const getEcommerceProductViewCount = () => {
  let header = {
      headers: {
        pubid: localStorage.getItem("pub_id"),
        channelid:localStorage.getItem('currentChannel')
      }
    };
    var date = new Date();
    var end_date = date.toISOString().slice(0,10);
    let start_date = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10); 


  // let url = `${
  //   process.env.REACT_APP_API_SERVER_HOST
  // }/report/productview?channelchecked=1&endDate=${end_date}&startDate=${start_date}`;
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/productview?channelchecked=1&countchecked=1&endDate=${end_date}&startDate=${start_date}`;
  return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};


const getEcommerceProductViewCountWeekly = () => {
  let header = {
      headers: {
        pubid: localStorage.getItem("pub_id"),
        channelid:localStorage.getItem('currentChannel')
      }
    };
    var date = new Date();
    var end_date = date.toISOString().slice(0,10);
    let start_date = addDay(date,-7) 


  // let url = `${
  //   process.env.REACT_APP_API_SERVER_HOST
  // }/report/productview?channelchecked=1&endDate=${end_date}&startDate=${start_date}`;
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/productview?channelchecked=1&countchecked=1&endDate=${end_date}&startDate=${start_date}`;
  return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};
const getEcommerceWeeklyProductViewGraph = () => {
  let header = {
      headers: {
        pubid: localStorage.getItem("pub_id"),
        channelid:localStorage.getItem('currentChannel')
      }
    };

  let date = new Date().toISOString().slice(0, 10);
  //let date = new Date().toISOString().slice(0, 10)
  let start_date = addDay(date, -7); 
  let end_date = addDay(date, 0);

  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/productview?channelchecked=1&endDate=${end_date}&startDate=${start_date}`;
  return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};

const getEcommerceTopView = () => {
  let header = {
      headers: {
        pubid: localStorage.getItem("pub_id"),
        channelid:localStorage.getItem('currentChannel')
      }
    };

  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/topViewedEcom`;
  return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};

const getShowLiveCount = async () => {
  let header = {
      headers: {
        pubid: localStorage.getItem("pub_id"),
        channelid:localStorage.getItem('currentChannel')
      }
    };

  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/channel/livecount`;
  try {
    const response = await axios
      .get(url, header);
    return response.data;
  } catch (e) {
    return false;
  }
};

const getEcommerceTopBought = () => {
  let header = {
      headers: {
        pubid: localStorage.getItem("pub_id"),
        channelid:localStorage.getItem('currentChannel')
      }
    };

  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/topPurchasedEcom`;
  return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};
const getEcommerceTopViewed = () => {
  let header = {
      headers: {
        pubid: localStorage.getItem("pub_id"),
      }
    };
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/topPurchase?`;

  return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};
const getProductsPerCategory = () => {
  let header = {
      headers: {
        pubid: localStorage.getItem("pub_id"),
      }
    };
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/productbyCategory?`;

  return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};

const getImpressionsCount = (pub_id, roll_id) => {
  console.log(
    `${
      process.env.REACT_APP_API_SERVER_HOST
    }/home/impressions?pub_id=${pub_id}&roll_id=${roll_id}`
  );
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/impressions?pub_id=${pub_id}&roll_id=${roll_id}`;

  return axios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};

const getFillRate = (pub_id, roll_id) => {
  console.log(
    `${
      process.env.REACT_APP_API_SERVER_HOST
    }/home/fillrate?pub_id=${pub_id}&roll_id=${roll_id}`
  );
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/fillrate?pub_id=${pub_id}&roll_id=${roll_id}`;

  return axios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};

const getRevenue = (pub_id, roll_id) => {
  console.log(
    `${
      process.env.REACT_APP_API_SERVER_HOST
    }/home/revenue?pub_id=${pub_id}&roll_id=${roll_id}`
  );
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/revenue?pub_id=${pub_id}&roll_id=${roll_id}`;

  return axios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return false;
    });
};

const getActiveUsers = (pub_id, data) => {
  let today = new Date().toISOString().slice(0, 10);
  let url = "";
  {
      url = `${
        process.env.REACT_APP_API_SERVER_HOST
      }/report/activeUsers?startDate=${today}&endDate=${today}&publisherchecked=1&publisher_id=${pub_id}`;
    }
    if (data) {
      url = url + `&channelchecked=${1}`;
    
          url = url + `&channel_id=${data}`;
        
      
    }  
    
    let header = {
      headers: {
        pubid: pub_id,
        channelid: data && data
      }
    };
    return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};

const getInstallationsToday = (pub_id, data) => {
  let today = new Date().toISOString().slice(0, 10);
  let url = "";
  {
      url = `${
        process.env.REACT_APP_API_SERVER_HOST
      }/report/newUsers?startDate=${today}`;
    }
    if(pub_id){
      url = url +`&publisherchecked=1&publisher_id=${pub_id}`
    }
    if (data) {
      url = url + `&channelchecked=${1}`;
    
          url = url + `&channel_id=${data}`;
        
      
    }  
    
    let header = {
      headers: {
        pubid: pub_id && pub_id,
        channelid: data && data
      }
    };
  //let url = `https://us-central1-gizmeon-222018.cloudfunctions.net/aggregation-api/totalUsers?publisherchecked=1&publisher_id=${pub_id}&startDate=${today}&endDate=${today}`;
  return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};

const getTotalViewers_minutes = (pub_id, data) => {
  let today = new Date().toISOString().slice(0, 10);
  let url = "";
  {
      url = `${
        process.env.REACT_APP_API_SERVER_HOST
      }/report/videoAnalytics?startDate=${today}`;
    }
    if(pub_id){
      url = url +`&publisherchecked=1&publisher_id=${pub_id}`
    }
    if (data) {
      url = url + `&channelchecked=${1}`;
    
          url = url + `&channel_id=${data}`;
        
      
    }  
    
    let header = {
      headers: {
        pubid: pub_id,
       // channelid: data && data
      }
    };
  //let today = new Date().toISOString().slice(0, 10);
  //let url = `https://us-central1-gizmeon-222018.cloudfunctions.net/aggregation-api/videoViews?publisherchecked=1&publisher_id=${pub_id}&startDate=${today}&endDate=${today}`;
  return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};

const getTodayViewedMinutes = (pub_id, roll_id) => {
  let today = new Date().toISOString().slice(0, 10);
  let url = `https://us-central1-gizmeon-222018.cloudfunctions.net/aggregation-api/videoDuration?publisherchecked=1&publisher_id=${pub_id}&startDate=${today}&endDate=${today}`;
  return axios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};

const getGraphData = (pub_id, roll_id, channel_id) => {
  let header = {
    headers: {
      pubid: pub_id,
      role_id: roll_id,
     // channelid: channel_id && channel_id
    }

  };
  if(channel_id){
    header.headers.channelid= channel_id
  }
  let date = new Date().toISOString().slice(0, 10);
  //let date = new Date().toISOString().slice(0, 10)
  let start_date = addDay(date, -6); 
  let end_date = addDay(date, 0);
  console.log("end date", end_date);
  let url = `${process.env.REACT_APP_API_SERVER_HOST}/graph/home`;
  return axios
    .get(url, header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};

const getNewSubscriptionsData = (pub_id, roll_id) => {
  let date = new Date().toISOString().slice(0, 10);
  //let date = new Date().toISOString().slice(0, 10)
  let start_date = addDay(date, 1); 
  console.log("start date", start_date);
  let end_date = addDay(date, -6);
  console.log("end date", end_date);

  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/new_subscriptions_graph_data?pub_id=${pub_id}&roll_id=${roll_id}&start_date=${start_date}&end_date=${end_date}`;
  return axios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};

const getCancellationsData = (pub_id, roll_id) => {
  let date = new Date().toISOString().slice(0, 10);
  let start_date = addDay(date, 1); 
  console.log("start date", start_date);
  let end_date = addDay(date, -6);
  console.log("end date", end_date);
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/cancellations_graph_data?pub_id=${pub_id}&roll_id=${roll_id}&start_date=${start_date}&end_date=${end_date}`;
  return axios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};

const getSubscriptionRevenueGraphData = (pub_id, roll_id) => {
  let date = new Date().toISOString().slice(0, 10);
  //let date = new Date().toISOString().slice(0, 10)
  let start_date = addDay(date, -5);
  let end_date = addDay(date, 1); 
  console.log("start date", start_date);
  console.log("end date", end_date);
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/subscriptions_revenue_graph_data?pub_id=${pub_id}&roll_id=${roll_id}&start_date=${start_date}&end_date=${end_date}`;
  return axios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};

const getRenewedSubscriptionRevenueGraphData = (pub_id, roll_id) => {
  let date = new Date().toISOString().slice(0, 10);
  //let date = new Date().toISOString().slice(0, 10)
  let start_date = addDay(date, 0);
  let end_date = addDay(date, -7); 
  console.log("start date", start_date);
  console.log("end date", end_date);
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/renewed_subscriptions_revenue_graph_data?pub_id=${pub_id}&roll_id=${roll_id}&start_date=${start_date}&end_date=${end_date}`;
  return axios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};

const getTopViewed = (pub_id, roll_id, channel_id) => {
  let date = new Date().toISOString().slice(0, 10);
  let start_date = addDay(date, 1); 
  console.log("start date", start_date);
  let end_date = addDay(date, -6);
  console.log("end date", end_date);
  let header = {
    headers: {
      pubid: pub_id,
      role_id: roll_id,
      //channelid: channel_id && channel_id
    }
  };

  if(channel_id){
    header.headers.channelid=  channel_id
  }
  let url = `${process.env.REACT_APP_API_SERVER_HOST}/home/topViewed`;
  return axios
    .get(url, header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};

const getUesrsPerDevice = (pub_id, roll_id,channel_id) => {
  let today = new Date().toISOString().slice(0, 10);

  let url = `${process.env.REACT_APP_API_SERVER_HOST}/report/activeUsers?startDate=${today}&publisherchecked=1&publisher_id=${pub_id}&appchecked=1`
  if(channel_id){
   url+=`&channelchecked=1&channel_id=${channel_id}`
  }
  //`https://us-central1-gizmeon-222018.cloudfunctions.net/aggregation-api/activeUsers?startDate=${today}&publisher_id=${pub_id}&publisherchecked=1&appchecked=1`;
  return axios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};

const getUesrsPerCountry = (pub_id, data) => {
  let today = new Date().toISOString().slice(0, 10);
  let url = "";
  {
      url = `${
        process.env.REACT_APP_API_SERVER_HOST
      }/report/activeUsers?startDate=${today}&endDate=${today}&publisherchecked=1&publisher_id=${pub_id}&countrychecked=1`;
    }
    if (data) {
      url = url + `&channelchecked=${1}`;
    
          url = url + `&channel_id=${data}`;
        
      
    }  
    
    let header = {
      headers: {
        pubid: pub_id,
        channelid: data && data
      }
    };
  //let url = `https://us-central1-gizmeon-222018.cloudfunctions.net/aggregation-api/activeUsers?startDate=${today}&publisher_id=${pub_id}&publisherchecked=1&countrychecked=1`;
  return axios
    .get(url,header)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};
const getProjectedRevenueReport = (pub_id, roll_id) => {
  let date_ob = new Date();
  date_ob.setMonth(date_ob.getMonth() + 1);
  let date = "01";
  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
  let year = date_ob.getFullYear();
  var next_month = year + "-" + month + "-" + date;
  var date_next_month = new Date(next_month);
  date_next_month.setMonth(date_next_month.getMonth() + 3);
  var third_month = [
    date_next_month.getFullYear(),
    ("0" + (date_next_month.getMonth() + 1)).slice(-2),
    ("0" + date_next_month.getDate()).slice(-2)
  ].join("-");
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/home/projectedSubscriptionRevenue?pub_id=${pub_id}&roll_id=${roll_id}&next_month=${next_month}&third_month=${third_month}`;
  return axios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};
const getRevenueData = (pub_id,channel_id) => {
  var params = {};
  let date = new Date().toISOString().slice(0, 10);
  let start_date = addDay(date, 0); 
let headers ={}
  let end_date = addDay(date, -6);
  if (pub_id) {
    headers.pubid=pub_id;
    params.publisher_id = pub_id;
    params.publisherchecked = 1;
  }
  if (channel_id) {
    headers.channelid=channel_id
    params.channel_id = channel_id;
    params.channelchecked = 1;
  }
  params.startDate = end_date;
  params.endDate = start_date;
  console.log(params);

  const customConfig = {
    headers,
    params: params
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/report/revenue",
      customConfig
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return [];
    });
};






///AVOD Report Graphs


const avodRevenueReportGraph = (data,pub_id) => {
  let date = new Date().toISOString().slice(0, 10);
  let end_date = addDay(date, 0); 
  let start_date = addDay(date, -6);
  if(!pub_id){
    pub_id =localStorage.getItem('pub_id')
  }
 

  console.log("start date graph", start_date);
  console.log("end date graph", end_date);
  let url = "";
 {
    url = `${
      process.env.REACT_APP_API_SERVER_HOST
    }/report/revenue?startDate=${start_date}&endDate=${end_date}&publisherchecked=1&publisher_id=${pub_id}&`;
  }
  if (data) {
    url = url + `&channelchecked=${1}`;
  
        url = url + `&channel_id=${data}`;
      
    
  }
  //let url = `${process.env.REACT_APP_API_SERVER_HOST}/report/revenueReport?startDate=${start_date}&endDate=${end_date}&publisherchecked=1&publisher_id=${pub_id}`
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then(response => {
      console.log(response.data);
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};

const getActiveUsersReportGraph = (pub_id,data) => {
  let date = new Date().toISOString().slice(0, 10);
  let end_date = addDay(date, 0); 
  let start_date = addDay(date, -6);
  console.log("start date graph", start_date);
  console.log("end date graph", end_date);

  let url = "";
{
    url = `${
      process.env.REACT_APP_API_SERVER_HOST
    }/report/activeUsers?startDate=${start_date}&endDate=${end_date}&publisherchecked=1&publisher_id=${pub_id}`;
  }
  if (data) {
    url = url + `&channelchecked=${1}`;
  
        url = url + `&channel_id=${data}`;
      
    
  }
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then(response => {
      console.log(response.data);
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};



const VodAnalyticsReportGraph = (pub_id,data) => {
  let date = new Date().toISOString().slice(0, 10);
  let end_date = addDay(date, 0); 
  let start_date = addDay(date, -6);
  console.log("start date graph", start_date);
  console.log("end date graph", end_date);
  let url = "";
{
    url = `${
      process.env.REACT_APP_API_SERVER_HOST
    }/report/videoAnalytics?startDate=${start_date}&endDate=${end_date}&publisherchecked=1&publisher_id=${pub_id}&`;
  }
  if (data) {
    url = url + `&channelchecked=${1}`;
  
        url = url + `&channel_id=${data}`;
      
    
  }
   if (localStorage.getItem("role_id")) {
   
    url = url + `&role_id=${localStorage.getItem("role_id")}`; 
  }
   if (localStorage.getItem("contentPartnerId")) {
   
    url = url + `&content_partner_id=${localStorage.getItem("contentPartnerId")}`; 
  }
  //let url = `${process.env.REACT_APP_API_SERVER_HOST}/report/videoDuration?startDate=${start_date}&endDate=${end_date}&publisherchecked=1&publisher_id=${pub_id}`
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then(response => {
      console.log(response.data);
      return response.data;
    })
    .catch(e => {
      return { data: false };
    });
};



const getPublishersList = () => {
  return axios.get(`${process.env.REACT_APP_API_SERVER_HOST}/publisher/list`,{headers:{
    role_id:localStorage.getItem('role_id')
  }}).then(response => {
      if (response.data) {
          console.log('publishers list ', response.data);
          return response.data
      } else {
          return false
      }
  }).catch(e => {
      return false
  })
}

const getChannelList = (pub_id) => {
  return axios.get(`${process.env.REACT_APP_API_SERVER_HOST}/channel/list/${pub_id}`, { headers: { pub_id } }).then(response => {
      if (response.data) {
          console.log('Channel list ', response.data);
          return response.data
      } else {
          return false
      }
  }).catch(e => {
      return false
  })
}
export const services = {
  getCount,
  getRequestCount,
  getImpressionsCount,
  getFillRate,
  getRevenue,
  getActiveUsers,
  getInstallationsToday,
  getTotalViewers_minutes,
  getTodayViewedMinutes,
  getGraphData,
  getNewSubscriptionsData,
  getCancellationsData,
  getSubscriptionRevenueGraphData,
  getRenewedSubscriptionRevenueGraphData,
  getTopViewed,
  avodRevenueReportGraph,
  getUesrsPerDevice,
  getUesrsPerCountry,
  getProjectedRevenueReport,
  getRevenueData,
  getActiveUsersReportGraph,
  VodAnalyticsReportGraph,
  getPublishersList,
  getChannelList,
  getEcommerceCount,
  getEcommerceTopViewed,
  getProductsPerCategory,
  getEcommerceOrderGraph,
  getEcommerceWeeklyProductViewGraph,
  getEcommerceProductViewCount,
  getEcommerceProductViewCountWeekly,
  getEcommerceTopView,
getEcommerceTopBought,
getShowLiveCount
  
};
