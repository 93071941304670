import axios from "axios";
import { result } from "lodash";
import dateFormatter from "date-and-time";
import moment from "moment";

function addDay(date, day) {
  // used to add day to the current date
  //var date =moment(date,"YYYY-MM-DD");
  var date = new Date(date);
  date.setDate(date.getDate() + +day);
  return date.toISOString().slice(0, 10);
}

function subscriptionUserDateConverter(date, day) {
  // used to add day to the current date
  var date =moment(date,"MM-DD-YYYY");
  var date = new Date(date);
  
  date.setDate(date.getDate() + +day);
  return date.toISOString().slice(0, 10);
}


function initialDateFormatter(date, day) {
  // used to add day to the current date
  var date = new Date(date);

  date.setDate(date.getDate() + +day);
  var date1 = dateFormatter.format(date, "MM-DD-YYYY");
  return date1;
}
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
function formatDayBquery(date, day) {
  // used to add day to the current date
  var date = new Date(date.replace(/-/g, "/"));
  //console.log(date,'aaaaaaaa');
  date.setDate(date.getDate() + +day);
  //console.log(date,'bbbbbbbbbb');
  let b = convert(date);
  // var date1 = dateFormatter.format(date, "YYYY-MM-DD");
  // return date1
  return b;
}

const cancellationReportDefault = (pub_id,currencyId,currencyFlag) => {
  let end_date = moment().add(1,'day').format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(1,'month').format("YYYY-MM-DD")

  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/cancel?pub_id=${pub_id}&start_date=${start_date}&end_date=${end_date}`;
  if(currencyFlag){
    url = url + `&currency_id=${currencyId}`
  }

  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      if (response.data.success === false) {
        return false;
      } else {
        return response.data;
      }
    })
    .catch((e) => {
      return false;
    });
};
const VodAnalyticsReportDefault = (pub_id, data) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(6,'day').format("YYYY-MM-DD") 
  let url = "";
  // if (publisherCheck()) {
  //   url = `${
  //     process.env.REACT_APP_API_SERVER_HOST
  //   }/report/videoAnalytics?startDate=${start_date}&endDate=${end_date}&publisherchecked=1&videochecked=1`;
  // } else
  {
    url = `${
      process.env.REACT_APP_API_SERVER_HOST
    }/report/contentPartnerRevenue?startDate=${start_date}&endDate=${end_date}&publisherchecked=1&publisher_id=${pub_id}&role_id=${localStorage.getItem("role_id")}`;
  }
  if (data && data[0]) {
    data = data[0];
    url = url + `&channelchecked=${1}`;
    if (data.channel_id !== null && data.channel_id) {
      url = url + `&channel_id=${data.channel_id}`;
    }
  }
 if(localStorage.getItem("role_id") == 8){
  url +=`&content_partner_checked=1&content_partner_id=${localStorage.getItem("contentPartnerId")}`
}
  // let url = `${process.env.REACT_APP_API_SERVER_HOST}/report/videoDuration?publisherchecked=1&publisher_id=${pub_id}&startDate=${start_date}&endDate=${end_date}`
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      if (response.data.success === false) {
        return false;
      } else {
        return response.data;
      }
    })
    .catch((e) => {
      return false;
    });
};
const VodAnalyticsReportGraph = (pub_id, data, filter) => {
  let date = new Date().toISOString().slice(0, 10);
  let end_date = filter?.end_date
    ? moment(filter.end_date).format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD"); //first day of this month
  let start_date = filter?.start_date
    ? moment(filter.start_date).format("YYYY-MM-DD")
    : moment().subtract(6,'day').format("YYYY-MM-DD");

  let url = "";
  // if (publisherCheck()) {
  //   url = `${
  //     process.env.REACT_APP_API_SERVER_HOST
  //   }/report/videoAnalytics?startDate=${start_date}&videochecked=1&publisherchecked=1`;
  // } else
  {
    url = `${
      process.env.REACT_APP_API_SERVER_HOST
    }/report/contentPartnerRevenue?startDate=${start_date}&endDate=${end_date}&publisherchecked=1&publisher_id=${pub_id}&role_id=${localStorage.getItem("role_id")}`;
  }
  if (data && data[0]) {
    data = data[0];
    url = url + `&channelchecked=${1}`;
    if (data.channel_id !== null && data.channel_id) {
      url = url + `&channel_id=${data.channel_id}`;
    }
  }
 if(localStorage.getItem("role_id") == 8){
  url +=`&content_partner_checked=1&content_partner_id=${localStorage.getItem("contentPartnerId")}`
}
 
  //let url = `${process.env.REACT_APP_API_SERVER_HOST}/report/videoDuration?startDate=${start_date}&endDate=${end_date}&publisherchecked=1&publisher_id=${pub_id}`
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { data: false };
    });
};
const ReportFilter = (pub_id, data, reportType,isSafari) => {
  let baseUrl = `${process.env.REACT_APP_API_SERVER_HOST}/report/`;
  switch (reportType) {
    case "cancel_report":
      {
        baseUrl = baseUrl + "cancel?";
      }
      break;
    case "refund_report":
      {
        baseUrl = baseUrl + "refund?";
      }
      break;
    case "renewal_report":
      {
        baseUrl = baseUrl + "renewal?";
      }
      break;
    case "non_renewal_report":
      {
        baseUrl = baseUrl + "nonrenewal?";
      }
      break;
    case "missMatch_report":
      {
        baseUrl = baseUrl + "mismatch?";
      }
      break;
    case "failed_renew_report":
      {
        baseUrl = baseUrl + "failedIdeabizRenewal?";
      }
      break;
    case "subscribed_user_report":
      {
        baseUrl = baseUrl + "subscribedUser?";

        if (data.status.value !== 1) {
          baseUrl = baseUrl + `&status=${data.status.id}`;
        }
      }

      break;
    case "upgrade_report":
      {
        baseUrl = baseUrl + "upgrade?";
      }
      break;
    case "revenue_report":
      {
        baseUrl = baseUrl + "subscriptionRevenue?";
        if (data.transaction.value !== 1) {
          baseUrl = baseUrl + `&transaction_type=${data.transaction.id}`;
        } else {
          baseUrl = baseUrl + `&transaction_type=${0}`;
        }
        // if (data.show.value !== 1) {
        //     baseUrl = baseUrl + `&show_id=${data.show.show_id}`

        // }
        if (data.appType.value) {
          baseUrl = baseUrl + `&app_checked=${1}`;
          if (data.appType.id !== null && data.appType.id) {
            baseUrl = baseUrl + `&app_id=${data.appType.app_type}`;
          }
        }
      }
      break;
    case "payPerView":
      {
        baseUrl = baseUrl + "payPerView?";
      }
      break;
    case "usage":
      {
        baseUrl = baseUrl + "usage?&videochecked=1&channelchecked=1";
      }
      break;
      case "coupon_revenue_report":
        {
          baseUrl = baseUrl + "couponSubscriptionRevenue?";
          if (data.transaction.value !== 1) {
            baseUrl = baseUrl + `&transaction_type=${data.transaction.id}`;
          } else {
            baseUrl = baseUrl + `&transaction_type=${0}`;
          }
          if (data.appType.value) {
            baseUrl = baseUrl + `&app_checked=${1}`;
            if (data.appType.id !== null && data.appType.id) {
              baseUrl = baseUrl + `&app_id=${data.appType.app_type}`;
            }
          }
         
        }
        break;
   case "content_partner_revenue":
      {
        baseUrl = baseUrl + "contentPartnerRevenue?";
      }
      break;
    default:
      break;
  }

  if (reportType === "subscribed_user_report") {
    baseUrl = baseUrl + `&start_date=${moment(data.start_date).format("YYYY-MM-DD")}`;
    baseUrl = baseUrl + `&end_date=${moment(data.end_date).format("YYYY-MM-DD")}`;
  } else if (reportType === "revenue_report") {
    baseUrl = baseUrl + `&start_date=${moment(data.start_date).format("YYYY-MM-DD")}`;
    baseUrl = baseUrl + `&end_date=${moment(data.end_date).format("YYYY-MM-DD")}`;
  } else if (reportType === "usage") {
    baseUrl = baseUrl + `&startDate=${moment(data.start_date).format("YYYY-MM-DD")}`;
    baseUrl = baseUrl + `&endDate=${moment(data.end_date).format("YYYY-MM-DD")}`;
  } else if (reportType === "content_partner_revenue") {
    baseUrl = baseUrl + `&startDate=${moment(data.start_date).format("YYYY-MM-DD")}`;
    baseUrl = baseUrl + `&endDate=${moment(data.end_date).format("YYYY-MM-DD")}`;
  }else if(isSafari) {
    baseUrl = baseUrl + `&start_date=${
      data.start_date}`;
    baseUrl = baseUrl + `&end_date=${
      data.end_date}`;
  }else {
    baseUrl = baseUrl + `&start_date=${moment(data.start_date).format("YYYY-MM-DD")}`;
    baseUrl = baseUrl + `&end_date=${moment(data.end_date).format("YYYY-MM-DD")}`;
  }

  if (data.isAppSelected) {
    if (reportType === "usage" || reportType === "content_partner_revenue") {
      baseUrl = baseUrl + `&appchecked=${1}`;
      if (data.appType.id !== null && data.appType.id) {
        baseUrl = baseUrl + `&app_id=${data.appType.id}`;
      }
    } else {
      baseUrl = baseUrl + `&app_checked=${1}`;
      if (data.appType.id !== null && data.appType.id) {
        baseUrl = baseUrl + `&app_id=${data.appType.app_type}`;
      }
    }
  }
  if (data.isCountrySelected) {
    if (reportType === "usage" || reportType === "content_partner_revenue") {
      baseUrl = baseUrl + `&countrychecked=${1}`;
      if (data.country.value && data.country.value !== 1) {
        baseUrl = baseUrl + `&country=${data.country.label}`;
      }
    } else {
      baseUrl = baseUrl + `&country_checked=${1}`;
      if (data.country.value && data.country.value !== 1) {
        baseUrl = baseUrl + `&country=${data.country.code}`;
      }
    }
  }
  if (data.isCitySelected) {
  if (reportType === "content_partner_revenue") {
      baseUrl = baseUrl + `&citychecked=1`;
      if (data?.city?.label !== "All") {
        baseUrl = baseUrl + `&city=${data.city.label}`;
      }
   }else{
      baseUrl = baseUrl + `&city_checked=1`;
      if (data?.city?.label !== "All") {
        baseUrl = baseUrl + `&city=${data.city.label}`;
      }
    }
   
  }
  if (data.isCouponChecked) {
  
    baseUrl = baseUrl + `&coupon_checked=1`;
    if (data?.coupon?.value) {
       baseUrl = baseUrl + `&coupon_id=${data?.coupon?.value}`;
    }
 
}

if (data.isContentPartnerSelected ) {
  
    baseUrl = baseUrl + `&content_partner_checked=1`;
    if (data?.contentPartner?.content_partner_id) {
       baseUrl = baseUrl + `&content_partner_id=${data?.contentPartner?.content_partner_id}`;
    }
 
}
if(localStorage.getItem("role_id") == 8){
    baseUrl = baseUrl + `&content_partner_checked=1&content_partner_id=${localStorage.getItem("contentPartnerId")}`;    
}
  if (
    data.subType.subscription_type_id !== null &&
    data.subType.subscription_type_id
  ) {
    if (reportType !== "usage") {
      baseUrl =
        baseUrl + `&subscription_type=${data.subType.subscription_type_id}`;
    } else {
      baseUrl =
        baseUrl + `&subscription_id=${data.subType.subscription_type_id}`;
    }
  }
  if (data.paymentMode.value && data.paymentMode.value !== 1) {
    baseUrl = baseUrl + `&mode_of_payment='${data.paymentMode.label}'`;
  }
  if (data?.video?.code) {
    baseUrl = baseUrl + `&video_id='${data.video.code}'`;
  }
  if (data?.show?.show_id) {
    if (reportType !== "usage") {
      baseUrl = baseUrl + `&show_id='${data.show.show_id}'`;

    } else {
      baseUrl = baseUrl + `&show_id=${data.show.show_id}`;
      if(data?.episode?.video_id){
        baseUrl = baseUrl + `&video_id=${data.episode.video_id}`;
      }
    }
  }
  if (data.isVideoChecked || reportType === "content_partner_revenue") {
  
    baseUrl = baseUrl + `&videochecked=1`;
    if (data?.video?.id) {
       baseUrl = baseUrl + `&video_id=${data?.video?.id}`;
    }
 
  }
   if (data.isShowchecked || reportType === "content_partner_revenue") {
  
    baseUrl = baseUrl + `&showchecked=1`;
    if (data?.show?.id) {
       baseUrl = baseUrl + `&show_id=${data?.show?.id}`;
    }
 
  }
  if (data?.video?.video_id) {

    if (reportType == "usage" || reportType == "subscribed_user_report" || reportType == "payPerView") {

      baseUrl = baseUrl + `&video_id=${data.video.video_id}`;
    }
  }
  
  if(data?.currencyValue?.value &&( reportType == "revenue_report" ||reportType == "renewal_report" ||reportType == "cancel_report" )  && data?.currencyFlag){
    baseUrl = baseUrl +`&currency_id=${data.currencyValue.value}`
  }
  //var row_count = params.offset;
  return axios
    .get(baseUrl, { headers: { pubid: pub_id } })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};
const subscribedUserVideo = (pub_id, data, reportType) => {
  let baseUrl = `${process.env.REACT_APP_API_SERVER_HOST}/video/list?type=subscribed_users_dropdown`;
 
    baseUrl = baseUrl + `&start_date=${moment(data.start_date).add(1,'day').format("YYYY-MM-DD")}`;
    baseUrl = baseUrl + `&end_date=${moment(data.end_date).add(1,'day').format("YYYY-MM-DD")}`;
 
 
  return axios
    .get(baseUrl, { headers: { pubid: pub_id } })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};
const getCountryList = () => {
  return axios
    .get(`${process.env.REACT_APP_API_SERVER_HOST}/country/list`)
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((e) => {
      return false;
    });
};
const getModeofPayment = () => {
   const config = {
    headers: {
      pubid: localStorage.getItem("pub_id"),
    },
  };
  return axios
    .get(`${process.env.REACT_APP_API_SERVER_HOST}/subscription/payment/mode`, config)
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((e) => {
      return false;
    });
};
const getShowList = (pubid) => {
  return axios
    .get(`${process.env.REACT_APP_API_SERVER_HOST}/show/list?dropdown=1`, {
      headers: { pubid },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((e) => {
      return false;
    });
};
const getSingleVideoList = (pubid) => {
  return axios
    .get(`${process.env.REACT_APP_API_SERVER_HOST}/video/list?dropdown=1`, {
      headers: { pubid },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((e) => {
      return false;
    });
};
const getContentPartnerVideoList = (pubid) => {
  var channelid=localStorage.getItem("currentChannel");
  var content_partner_id = localStorage.getItem("contentPartnerId");
  var role_id = localStorage.getItem("role_id");
  return axios
    .get(
      `${
        process.env.REACT_APP_API_SERVER_HOST
      }/video/list?type=schedule_dropdown`,
      { headers: { pubid,channelid,role_id,content_partner_id } }
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((e) => {
      return false;
    });
};
const getVideoList = (videoId) => {
  let url = `${process.env.REACT_APP_API_SERVER_HOST}/show/${videoId}/videos?dropdown=1`;

  return axios
    .get(url, { headers: { pubid: localStorage.getItem("pub_id") } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return false;
    });
};
const getSubscriptionType = () => {
  const config = {
    headers: {
      pubid: localStorage.getItem("pub_id"),
    },
  };
  return axios
    .get(`${process.env.REACT_APP_API_SERVER_HOST}/subscription/type`, config)
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((e) => {
      return false;
    });
};

const getAppType = (pubid, channelid) => {
  return axios
    .get(`${process.env.REACT_APP_API_SERVER_HOST}/app/list?dropdown=1`, {
      headers: { pubid: pubid },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((e) => {
      return false;
    });
};
const getContentPartner = (pubid,channelid) => {
  const customConfig = {
    headers: {
      pubid: localStorage.getItem("pub_id")
    },
  };
  if(channelid){
    customConfig.headers.channelid = channelid ;
  }
  return axios
    .get(`${process.env.REACT_APP_API_SERVER_HOST}/contentPartner/list`, customConfig)
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((e) => {
      return false;
    });
};
const getCancellationsDataGraph = (pub_id) => {
  //let date = new Date("2020-08-10")//.toISOString().slice(0, 10)
  let date = new Date().toISOString().slice(0, 10);
  let end_date = addDay(date, 0); //first day of this month
  let start_date = addDay(date, -7);

  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/graph/cancel?pub_id=${pub_id}&start_date=${start_date}&end_date=${end_date}`;
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { data: false };
    });
};

const getFailedRenewalDataGraph = (pub_id) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(7,'day').format("YYYY-MM-DD")
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/graph/failedRenewal?pub_id=${pub_id}&start_date=${start_date}&end_date=${end_date}`;
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { data: false };
    });
};
const getRefundDataGraph = (pub_id) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(365,'day').format("YYYY-MM-DD")
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/graph/refund?&start_date=${start_date}&end_date=${end_date}`;
  ////?&start_date=${start_date}&end_date=${end_date}
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { data: false };
    });
};

const refundReportDefault = (pub_id) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(1,'month').format("YYYY-MM-DD")
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/refund?&start_date=${start_date}&end_date=${end_date}`;
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      if (response.data.success === false) {
        return false;
      } else {
        // console.log(response.data, "refund report without filters");
        return response.data;
      }
    })
    .catch((e) => {
      return false;
    });
};

//// Renewal Report ////

const getRenewalDataGraph = (pub_id) => {
  // let date = new Date("2020-10-11")//.toISOString().slice(0, 10)
  // let date = new Date().toISOString().slice(0, 10);
  // let end_date = addDay(date, 0); //first day of this month
  // let start_date = addDay(date, -6);
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(6,'day').format("YYYY-MM-DD")
  // console.log("start date graph", start_date);

  // console.log("end date graph", end_date);
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/graph/renewal?&start_date=${start_date}&end_date=${end_date}`;
  ////?&start_date=${start_date}&end_date=${end_date}
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { data: false };
    });
};

const renewalReportDefault = (pub_id,currencyId,currencyFlag) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(1,'month').format("YYYY-MM-DD")
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/renewal?&start_date=${start_date}&end_date=${end_date}`;
  if(currencyFlag){
    url = url + `&currency_id=${currencyId}`
  }

  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      if (response.data.success === false) {
        return false;
      } else {
        return response.data;
      }
    })
    .catch((e) => {
      return false;
    });
};

/// Non*Renewal Report

const getNonRenewalDataGraph = (pub_id) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(6,'day').format("YYYY-MM-DD")

  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/graph/nonrenewal?&start_date=${start_date}&end_date=${end_date}`;
  ////?&start_date=${start_date}&end_date=${end_date}
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { data: false };
    });
};

const nonNenewalReportDefault = (pub_id) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(1,'month').format("YYYY-MM-DD")
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/nonrenewal?&start_date=${start_date}&end_date=${end_date}`;
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      if (response.data.success === false) {
        return false;
      } else {
        return response.data;
      }
    })
    .catch((e) => {
      return false;
    });
};

/////// mis-match report
const misMatchReportDefault = (pub_id) => {
   let end_date = moment().add(1,'day').format("YYYY-MM-DD"); //first day of this month
  let start_date = moment().format("YYYY-MM-DD");
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/mismatch?&start_date=${start_date}&end_date=${end_date}`;
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      if (response.data.success === false) {
        return false;
      } else {
        return response.data;
      }
    })
    .catch((e) => {
      return false;
    });
};

/// Active subscriptions report
const activeSubscriptionReportDefault = (pub_id) => {
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/activeSubscription`;
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      if (response.data.success === false) {
        return false;
      } else {
        return response.data;
      }
    })
    .catch((e) => {
      return false;
    });
};

const getActiveSubscriptionsGraph = (pub_id) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(6,'day').format("YYYY-MM-DD")

  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/graph/subscribedUser?&start_date=${start_date}&end_date=${end_date}`;
  ////?&start_date=${start_date}&end_date=${end_date}
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { data: false };
    });
};

const getSubscriptionsReport = (pub_id,currencyId) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(1,'month').format("YYYY-MM-DD")
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/subscribedUser?&start_date=${start_date}&end_date=${end_date}`;

  ////?&start_date=${start_date}&end_date=${end_date}
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { data: false };
    });
};

// FIALED REPORT
const fialedRenewReportDefault = (pub_id) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(1,'month').format("YYYY-MM-DD")
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/failedIdeabizRenewal?pub_id=${pub_id}&start_date=${start_date}&end_date=${end_date}`;
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      if (response.data.success === false) {
        return false;
      } else {
        // console.log(response.data, "failed renew report without filters");
        return response.data;
      }
    })
    .catch((e) => {
      return false;
    });
};

const upgradeReportDefault = (pub_id) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(1,'month').format("YYYY-MM-DD")

  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/upgrade?pub_id=${pub_id}&start_date=${start_date}&end_date=${end_date}`;
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      if (response.data.success === false) {
        return false;
      } else {
        return response.data;
      }
    })
    .catch((e) => {
      return false;
    });
};

const getupgradeReportGraph = (pub_id) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(6,'day').format("YYYY-MM-DD")
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/graph/upgrade?&start_date=${start_date}&end_date=${end_date}`;
  ////?&start_date=${start_date}&end_date=${end_date}
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { data: false };
    });
};

// revenue rep

const revenueReportDefault = (pub_id,currencyId,currencyFlag) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(1,'month').format("YYYY-MM-DD")
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/subscriptionRevenue?&start_date=${start_date}&end_date=${end_date}&transaction_type=${0}`;

  if(currencyFlag){
    url = url + `&currency_id=${currencyId}`
  }
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      if (response.data.success === false) {
        return false;
      } else {
        return response.data;
      }
    })
    .catch((e) => {
      return false;
    });
};

const getRevenueReportGraph = (pub_id) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date =  moment().subtract(6,'day').format("YYYY-MM-DD")
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/graph/subscriptionRevenue?&start_date=${start_date}&end_date=${end_date}`;
  ////?&start_date=${start_date}&end_date=${end_date}
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { data: false };
    });
};

const payPerViewDefault = (pub_id) => {
  let start_date=moment().clone().startOf('month').format("YYYY-MM-DD");
  let end_date= moment().clone().endOf('month').format("YYYY-MM-DD");
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/payPerView?&start_date=${start_date}&end_date=${end_date}`;
if(localStorage.getItem("role_id") == 8){
  url +=`&content_partner_checked=1&content_partner_id=${localStorage.getItem("contentPartnerId")}`
}
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => false);
};

const getPayPerViewReportGraph = (pub_id) => {
  let end_date = moment().format("YYYY-MM-DD"); //first day of this month
  let start_date = moment().subtract(6,'day').format("YYYY-MM-DD");
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/graph/payPerView?&start_date=${start_date}&end_date=${end_date}`;
  if(localStorage.getItem("role_id") == 8){
  url +=`&content_partner_checked=1&content_partner_id=${localStorage.getItem("contentPartnerId")}`
}
  ////?&start_date=${start_date}&end_date=${end_date}
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { data: false };
    });
};
const getUsageReportDataGraph = (pub_id, filter) => {
  let end_date = moment(filter.end_date).format("YYYY-MM-DD"); //first day of this month
  let start_date = moment(filter.start_date).format("YYYY-MM-DD");
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/graph/usage?startDate=${start_date}&endDate=${end_date}&videochecked=1&publisher_id=${pub_id}&publisherchecked=1`;
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { data: false };
    });
};

const usageReportDefault = (pub_id, filter) => {
  let end_date = moment(filter.end_date).format("YYYY-MM-DD") //first day of this month
  let start_date = moment(filter.start_date).format("YYYY-MM-DD");
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/usage?&startDate=${start_date}&endDate=${end_date}&videochecked=1&publisher_id=${pub_id}&publisherchecked=1`;

  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      return response.data;
    })
    .catch((e) => []);
};

const CouponRevenueReportDefault = (pub_id) => {
  let date = new Date().toISOString().slice(0, 10);
  let end_date = moment().format("YYYY-MM-DD"); //first day of this month
  let start_date = moment().subtract(30,'day').format("YYYY-MM-DD");
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/couponSubscriptionRevenue?&start_date=${start_date}&end_date=${end_date}&transaction_type=${0}&coupon_checked=1`;
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      if (response.data.success === false) {
        return false;
      } else {
        return response.data;
      }
    })
    .catch((e) => {
      return false;
    });
};

const CouponRevenueReportDefaultGraph = (pub_id) => {
  let end_date = moment().format("YYYY-MM-DD") //first day of this month
  let start_date = moment().subtract(6,'day').format("YYYY-MM-DD");

  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/report/couponSubscriptionRevenue?&start_date=${start_date}&end_date=${end_date}&transaction_type=${0}&coupon_checked=1&graph_checked=1`;
  return axios
    .get(url, { headers: { pubid: pub_id } })
    .then((response) => {
      if (response.data.success === false) {
        return false;
      } else {
        return response.data;
      }
    })
    .catch((e) => {
      return false;
    });
};
const getCurrencyList = () => {
  const config = {
      headers:{
          pubid:localStorage.getItem('pub_id')
      }
    };
  let url = `${
    process.env.REACT_APP_API_SERVER_HOST
  }/currency/list?`;

  return axios.get(url, config).then(response => {
      return response.data
  }).catch(e => {
      return []
  })
}
export const service = {
  getCancellationsDataGraph,
  cancellationReportDefault,
  getCountryList,
  getSubscriptionType,
  getShowList,
  getSingleVideoList,
  getAppType,
  ReportFilter,
  getRefundDataGraph,
  refundReportDefault,
  getRenewalDataGraph,
  renewalReportDefault,
  nonNenewalReportDefault,
  getNonRenewalDataGraph,
  misMatchReportDefault,
  activeSubscriptionReportDefault,
  fialedRenewReportDefault,
  getActiveSubscriptionsGraph,
  upgradeReportDefault,
  getupgradeReportGraph,
  revenueReportDefault,
  getRevenueReportGraph,
  getSubscriptionsReport,
  getVideoList,
  payPerViewDefault,
  getPayPerViewReportGraph,
  getFailedRenewalDataGraph,
  getUsageReportDataGraph,
  usageReportDefault,
  getModeofPayment,
  subscribedUserVideo,
  CouponRevenueReportDefault,
  CouponRevenueReportDefaultGraph,
  getContentPartner,
  VodAnalyticsReportDefault,
  VodAnalyticsReportGraph,
  getContentPartnerVideoList,
  getCurrencyList
};
