import axios from "axios";

const publisherId = localStorage.getItem('pub_id')
const channelId = localStorage.getItem('currentChannel')

const getDonationsData = async (params) => {

  const config = {
    params,
    headers: {
      pubid: publisherId,
      channelid: channelId
    }
  };
  let url = `${process.env.REACT_APP_API_SERVER_HOST}/report/donations`;

  return axios.get(url, config)
}

const getDirectDebitData = async (params) => {

  const config = {
    params,
    headers: {
      pubid: publisherId,
      channelid: channelId
    }
  };
  let url = `${process.env.REACT_APP_API_SERVER_HOST}/report/donations/direct-debit`;

  return axios.get(url, config)
}
function  archiveDonationData(donations) {
  const config = {
      headers: {
          pubid: localStorage.getItem('pub_id'),
          channelid:localStorage.getItem("currentChannel")
      },
  };
  const data = {
    user_donations:donations
  };
  return axios
    .post(
      process.env.REACT_APP_API_SERVER_HOST +`/donations/delete`,
      data,config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export const service = {
  getDonationsData,
  getDirectDebitData,
  archiveDonationData
}