import { config } from "aws-sdk";
import axios from "axios";
import { toast } from "react-toastify";

let header = {
  headers: {
    pubid: localStorage.getItem("pub_id"),
    role_id: localStorage.getItem("role_id"),
  },
};

const getPublishersList = () => {
  let url = `${process.env.REACT_APP_API_SERVER_HOST}/publisher/list`;
  return axios
    .get(url, { headers: { role_id: localStorage.getItem("role_id") } })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((e) => false);
};

const deletePublisher = (pubid) => {
  let url = `${process.env.REACT_APP_API_SERVER_HOST}/publisher/delete/${pubid}`;
  return axios
    .post(url, {}, { headers: { role_id: localStorage.getItem("role_id") } })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((e) => false);
};

const getCountryList = () => {
  return axios
    .get(`${process.env.REACT_APP_API_SERVER_HOST}/country/list`, header)
    .then((response) => {
      if (response.data) {
        console.log("country l ", response.data);
        return response.data;
      } else {
        return false;
      }
    })
    .catch((e) => {
      return false;
    });
};

const getCurrencyList = () => {
  return axios
    .get(`${process.env.REACT_APP_API_SERVER_HOST}/currency/list`, header)
    .then((response) => {
      if (response.data) {
        console.log("currency ", response.data);
        return response.data;
      } else {
        return false;
      }
    })
    .catch((e) => {
      return false;
    });
};

const addPublisher = (inputData, type, pubid) => {
  var formData = {};
  let imageForm = new FormData();
  console.log(inputData);

  if (inputData.isAllCountryChecked) {
    inputData.countries = [0];
  } else {
    inputData.countries = inputData.countryList.map((element) => element.value);
  }
  inputData.external_pub = inputData.isExternalPublisher ? 1 : 0;
  inputData.registration_mandatory = inputData.isRegistrationMandatory ? 1 : 0;
  inputData.subscription_mandatory = inputData.isSubscriptionMandatory ? 1 : 0;
  inputData.currency = inputData?.currency?.value;
  inputData.bucketType = inputData?.bucketType?.value;
  inputData.mfa_enabled = inputData.isMfaEnabled ? 1 : 0;
  inputData.needed_4k = inputData.is4Kenabled 
  inputData.timezone = inputData.timezone 
  inputData.quota_limit = inputData.quota_limit 
  

  // formData.append('pubName',inputData.name);
  // formData.append('website',inputData.website);
  // formData.append('contact',inputData.contact);
  // //formData.append('logo',inputData.name);
  // formData.append('user_name',inputData.username);
  // formData.append('password',inputData.password);
  // formData.append('external_pub',inputData.external_pub);
  // formData.append('email',inputData.email);
  // formData.append('currency',inputData.currency);
  // formData.append('publisher_input_bucket',inputData.publisherInputBucket);
  // formData.append('publisher_output_bucket',inputData.publisherOutputBucket);
  // formData.append('publisher_key_bucket',inputData.publisherKeyBucket);
  // formData.append('trailor_bucket',inputData.TrailorBucket);
  // formData.append('region_code',inputData.regionCode);
  // formData.append('registration_mandatory',inputData.registration_mandatory);
  // formData.append('subscription_mandatory',inputData.subscription_mandatory);
  // formData.append('bucket_type',inputData.bucketType);
  // formData.append('countries',inputData.countries);
  // formData.append('isAllCountryChecked',inputData.isAllCountryChecked ? 1 : 0);

  formData["pubName"] = inputData.name;
  formData["website"] = inputData.website;
  formData["contact"] = inputData.contact;
  //'logo'=inputData.name;
  formData["user_name"] = inputData.username;
  formData["password"] = inputData.password;
  formData["external_pub"] = inputData.external_pub;
  formData["email"] = inputData.email;
  formData["currency"] = inputData.currency2?.value;
  formData["publisher_input_bucket"] = inputData.publisherInputBucket;
  formData["publisher_output_bucket"] = inputData.publisherOutputBucket;
  formData["publisher_key_bucket"] = inputData.publisherKeyBucket;
  formData["trailor_bucket"] = inputData.TrailorBucket;
  formData["region_code"] = inputData.regionCode;
  formData["registration_mandatory"] = inputData.registration_mandatory;
  formData["subscription_mandatory"] = inputData.subscription_mandatory;
  formData["bucket_type"] = inputData.bucketType2?.value;
  formData["cdn_type"] = inputData.cdnType?.value;
  formData["countries"] = inputData.countries;
  formData["isAllCountryChecked"] = inputData.isAllCountryChecked ? 1 : 0;
  formData["mfa_enabled"] = inputData.mfa_enabled ? 1 : 0;
  formData["needed_4k"] = inputData.is4Kenabled 
  formData["timezone"] = inputData.timezone?.value 
  formData["quota_limit"] = inputData.quota_limit  ? inputData.quota_limit  : 5000
  if(inputData.newPassword){
    formData["newPassword"] = inputData.newPassword 
  }


  const config = {
    headers: {
      "Content-Type": "application/json",
      pubid: localStorage.getItem("pub_id"),
      role_id: localStorage.getItem("role_id"),
    },
  };
  console.log("data",formData,"comfig",config)
  if (type == "add") {
    if (inputData.logoUrl?.raw) {
      inputData.logoUrl?.raw &&
        imageForm.append("publisher_logo", inputData.logoUrl?.raw);
      imageForm.append("is_show_video", 2);
      return axios
        .post(
          process.env.REACT_APP_API_SERVER_HOST + "/upload/thumbnail",
          imageForm,
          header
        ) //to upload images to S3 bucket
        .then((response) => {
          let data = response.data;
          if (response.data.success) {
            formData["logo"] = response.data.data[0].filename;
            return axios
              .post(
                process.env.REACT_APP_API_SERVER_HOST + "/publisher/add",
                JSON.stringify(formData),
                config
              )
              .then((response) => {
                return response.data;
              })
              .catch((e) => {
                return false;
              });
          } else {
            toast.error("Error Occured !");
            return false;
          }
        })
        .catch((e) => false);
    }
  } else if (type == "update") {
    config.headers.pubid = pubid;
    formData["pubid"] = pubid;
    if (inputData.logoUrl?.raw) {
      inputData.logoUrl?.raw &&
        imageForm.append("publisher_logo", inputData.logoUrl?.raw);
      imageForm.append("is_show_video", 2);
      return axios
        .post(
          process.env.REACT_APP_API_SERVER_HOST + "/upload/thumbnail",
          imageForm,
          header
        ) //to upload images to S3 bucket
        .then((response) => {
          let data = response.data;
          if (response.data.success) {
            formData["logo"] = response.data.data[0].filename;
            console.log(inputData, response.data.data[0].filename);
            let url=process.env.REACT_APP_API_SERVER_HOST + "/publisher/update"
            return axios
              .post(
                url,
                JSON.stringify(formData),
                config
              )
              .then((response) => {
                return response.data;
              })
              .catch((e) => {
                return false;
              });
          } else {
            toast.error("Error Occured !");
            return false;
          }
        })
        .catch((e) => false);
    } else {
      formData["logo"] = inputData.logoUrl;
      console.log(inputData, formData);
      return axios
        .post(
          process.env.REACT_APP_API_SERVER_HOST + "/publisher/update",
          JSON.stringify(formData),
          config
        )
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          return false;
        });
    }
  }
};

const getInitials = (pubid) => {
let url = process.env.REACT_APP_API_SERVER_HOST + "/publisher/" + pubid
  return axios
    .get(url, header)
    .then((response) => response.data && response.data)
    .catch((e) => false);
};

const impersonatePublisher = (pubid) => {
  const customConfig = {
    headers: {}
  };
  if (localStorage.getItem("role_id") == 7) {
    customConfig.headers.role_id = localStorage.getItem("role_id");
  }
  let url = `${process.env.REACT_APP_API_SERVER_HOST}/publisher/impersonate/${pubid}`;
  return axios
    .post(
      url,
      { pubid },
      customConfig
    )
    .then((response) => {
      return response.data;
    })
    .catch(async (error) => {
      return error.response.status == 400 ? error.response.data : [];
    });
}
async function disableOrEnablePublisher(delete_status,pubid) {

  const data ={
    delete_status,
    pubid: pubid,
  }
  try {
    const response = await axios
      .post(process.env.REACT_APP_API_SERVER_HOST + '/publisher/disable', data);
    return response.data;
  } catch (error) {
    return [];
  }
}

function getTimeZone() {
  const customConfig = {
    headers: {
      pubid: localStorage.getItem("pub_id"),
    },
  };

  return axios
    .get(`${process.env.REACT_APP_API_SERVER_HOST}/timezone/list`, customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export const pubService = {
  getPublishersList,
  deletePublisher,
  getCountryList,
  getCurrencyList,
  addPublisher,
  getInitials,
  impersonatePublisher,
  disableOrEnablePublisher,
  getTimeZone
};
