import React from 'react';
import { getGrays, getPosition, isIterableArray, numberFormatter } from '../../../helpers/utils';
import MarketShareItem from '../../dashboard-alt/MarketShareItem';
import { Card, CardBody, Col, Row } from 'reactstrap';
import echarts from 'echarts/lib/echarts';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import 'echarts/lib/chart/pie';
import { useContext } from 'react';
import AppContext from '../../../context/Context';

const getOption = (data, isDark) => {
    const grays = getGrays(true);
    return {
        color: data.map(d => d.color),
        tooltip: {
            trigger: 'item',
            padding: [0, 0],
            backgroundColor: grays.white,
            textStyle: { color: grays.black },
            transitionDuration: 20,
            borderColor: grays['300'],
            borderWidth: 1,
            formatter: function (params) {
                return `<strong>${params.data.name}:</strong> ${params.percent}%`;
            }
        },
        position(pos, params, dom, rect, size) {
            return getPosition(pos, params, dom, rect, size);
        },
        legend: { show: false },
        series: [
            {
                type: 'pie',
                radius: ['100%', '0%'],
                avoidLabelOverlap: true,
                hoverAnimation: false,
                itemStyle: {
                    borderWidth: 0,
                    borderColor: isDark ? '#0E1C2F' : '#fff'
                },
                label: { show: false },
                labelLine: { normal: { show: false } },
                data: data
            }
        ]
    };
};

const PiChart = ({ data, title }) => {
    const { isDark } = useContext(AppContext);
    const totalShare = data.map(d => d.value).reduce((total, currentValue) => total + currentValue, 0);
    let renderData = null;
  //  console.log(data,'tttttttttttttttttttttttttttttttttttttt',title);

   data&& data.sort((a,b)=>{
        return b?.value -a?.value
    })
    if (data.length !== 0) {
        renderData = isIterableArray(data) &&
            data.map(({ id, ...rest }) => <MarketShareItem {...rest} totalShare={totalShare} key={id} />)
    } else {
        renderData = <p>No data available!</p>
    }

    return (
        <Card className="h-md-100">
            <CardBody>
                <Row >
                    
                    <Col style={{  }}>
                    <h6 className="mt-1" style={{marginBottom: '20px'}}>{title}</h6>

                        <div >
                            <ReactEchartsCore
                                echarts={echarts}
                                option={getOption(data, isDark)}
                                style={{ width: '200%', height: '200%', right: '50%' }}
                            />

                        </div>
                        <div className="fs--2 mt-3">
                            {renderData}
                        </div>
                    </Col>
                    {/* <Col xs={5} sm={6} className="col-xxl pr-2">
                        <div className="fs--2 mt-3">
                            {renderData}
                        </div>
                    </Col> */}
                </Row>
            </CardBody>
        </Card>
    );
};



export default PiChart;
