import React from 'react'
import { Card, CardBody, CardImg, CardSubtitle, CardTitle, Row } from 'reactstrap'
import Background from '../../common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../assets/img/illustrations/corner-3.png';

const getImage = number => {
    switch (number) {
        case 1:
            return corner1;
        case 2:
            return corner2;
        case 3:
            return corner3;
        default:
            return corner1;
    }
};
const CardDashboard = ({ title, value, backgroundImage }) => {

    return (
        <div>
            <Card  >
                <Row>
                    <Background image={getImage(backgroundImage)} className="bg-card" />
                    <CardBody className="position-relative">
                        <h6>{title}</h6>
                        <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif">{value}</div>
                    </CardBody>
                </Row>

            </Card>
        </div>
    )
}

export default CardDashboard;