import React, { useContext } from 'react';
import FalconCardHeader from '../../common/FalconCardHeader';
import { Card, CardBody } from 'reactstrap';
import echarts from 'echarts/lib/echarts';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import { getPosition, getGrays } from '../../../helpers/utils';
import AppContext from '../../../context/Context';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';

const getOption = (graphTitle, data, colors, isDark) => {
    const grays = getGrays(true);


    return {
        dataset: { source: data },
        tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: grays.white,
            borderColor: grays['300'],
            borderWidth: 1,
            textStyle: { color: grays.dark },
            transitionDuration: 0,
            position(pos, params, dom, rect, size) {
                return getPosition(pos, params, dom, rect, size);
            },
            formatter: function (params) {
                return `<div class="font-weight-semi-bold">${params.seriesName}</div><div class="fs--1 text-600">
        <strong>${params.name}:</strong> ${params.value[params.componentIndex + 1]}
      </div>`;
            }
        },
        legend: {
            data: graphTitle,
            left: 'left',
            itemWidth: 10,
            itemHeight: 10,
            borderRadius: 0,
            icon: 'circle',
            inactiveColor: grays['500'],
            textStyle: { color: grays['1100'] }
        },
        xAxis: {
            type: 'category',
            axisLabel: { color: grays['400'] },
            axisLine: {
                lineStyle: {
                    color: grays['300'],
                    type: 'dashed'
                }
            },
            axisTick: false,
            boundaryGap: true
        },
        yAxis: {
            axisPointer: { type: 'none' },
            axisTick: 'none',
            splitLine: {
                lineStyle: {
                    color: grays['300'],
                    type: 'dashed'
                }
            },
            axisLine: { show: false },
            axisLabel: { color: grays['400'] }
        },
        series: data[0].slice(1).map((value, index) => ({
            type: 'bar',
            barWidth: '12%',
            barGap: '30%',
            label: { normal: { show: false } },
            z: 10,
            itemStyle: {
                normal: {
                    barBorderRadius: [10, 10, 0, 0],
                    color: colors[index]
                }
            }
        })),
        grid: { right: '5%', left: '50px', bottom: '10%', top: '20%' }
    };
};

const BarGraph = ({ data, colors, className, title, graphTitle }) => {
    const { isDark } = useContext(AppContext);
    
    let body = null
   
    if (data.length === 0) {
        body = <p>No data available!</p>
    } else {

        body = <ReactEchartsCore
            echarts={echarts}
            option={getOption(graphTitle, data, colors, true)}
            style={{ minHeight: '18.75rem' }}
        />
    }

    return (
        <Card className={className}>
            <FalconCardHeader title={title} titleTag="h6" className="py-2">
            </FalconCardHeader>
            <CardBody className="h-100">
                {body}
            </CardBody>
        </Card>
    );
};


export default BarGraph;
